import React, { ComponentProps } from 'react';
import get from 'lodash.get';

import isGenderSuitableForTreatment from './helpers/isGenderSuitableForTreatment';

import SelectGender from '../../../_ui/_blocks/Form/Inputs/SelectGender/SelectGender';
import { ConsultationMeta } from '../../../../app/(consultation)/[dynamicRoute]/consultation/_components/Context/types/consultationDataTypes';

type SelectGenderPropsType = ComponentProps<typeof SelectGender>;
type Value = SelectGenderPropsType['gender'];

// TODO: Required is not used so placed an _ in front of it for now to disable the unused error.
export const validation = (val: Value, _required: boolean | null, consultationMeta: ConsultationMeta) => {
    const treatmentGender = get(consultationMeta, 'selectedVariant.gender');
    const questionValid = !!val && isGenderSuitableForTreatment(val, treatmentGender);

    return !!questionValid;
};

interface Props {
    value: Value;
    onChange: (val: string, isValid: boolean) => void;
    isValid: boolean;
    consultationMeta: ConsultationMeta;
}

/**
 * Question type === gender
 */
const QTGender = ({ value = '', onChange, isValid, consultationMeta }: Props) => {
    const handleOnChange = (val: any) => {
        onChange(val, validation(val, null, consultationMeta));
    };

    return (
        <div data-testid="select">
            <SelectGender
                value={value}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => handleOnChange(e.target.value)}
                isValid={isValid}
            />
        </div>
    );
};

export const defaultErrorMessage = 'Sorry, we are not able to provide this treatment to patients with the biological sex that you have selected.';

export default QTGender;
